import React, { useEffect, useState } from "react";
import { actionGetRptLogPortico } from "../../../features/relatorios/rptLogPortico/slices";
//import { actionGetGradeBySetor } from "../../../features/"
import factoryFields from "../../../helpers/factory-fields";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Button from "../../Button";
import Input from "../../Inputs";
import Select from "../../Select";
import FilterSetorMonitora from "../../FilterSetoresMonitora";

const initialState = {
    idSetor: '',//valor padrão
    reportType: '',
    dataIni: '',
    dataFim: '',
    intervalo: ''
} as any;

const RptLogPortico = () => {
  const [state, setState] = useState(initialState);
  const dispatch = useAppDispatch();
  const { getRptLogPortico } = useAppSelector((state) => state);
  const [setorSelecionado, setSetorSelecionado] = useState("selecione");
  const [erroSetor, setErroSetor] = useState(false);
  const [erroDataIni, setErroDataIni] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroFormato, setErroFormato] = useState(false);
  const [erroIntervalo, setErroIntervalo] = useState(false);
  //const [erroIntervalo, setErroIntervalo] = useState(false);

  // useEffect(() => {
  //   dispatch(actionGetRptHistoricosDispositivos());
  // }, []);
    const getSetorSelected = (filter) => {
        const selectedFilter = filter !== null ? filter : '';
        console.log(selectedFilter)
        setState({ ...state, idSetor: selectedFilter })
        //checa o estado do select dos canais de inspeção
        setSetorSelecionado(filter);
        setErroSetor(false);
    }

  const handlerChange = (e: any) => {
    const { name, value } = e.currentTarget;
    setState({
      ...state,
      [name]: value,
    });
    console.log(state.dataIni, state.dataFim)
  };

    const handlerReport = () => {
      let erro = false;

      // Verificar setor selecionado
      if (setorSelecionado === "selecione" || !setorSelecionado) {
          setErroSetor(true);
          erro = true;
      } else {
          setErroSetor(false);
      }

      if (!state.reportType) {
          setErroFormato(true);
          erro = true;
      } else {
          setErroFormato(false);
      }

      if (!state.dataIni) {
          setErroDataIni(true);
          erro = true;
      } else {
          setErroDataIni(false);
      }

      if (!state.dataFim) {
          setErroDataFim(true);
          erro = true;
       } else {
          setErroDataFim(false);
      }

      if (!state.intervalo) {
        setErroIntervalo(true);
        erro = true;
      } else {
        setErroIntervalo(false);
      } 
    
      // Validar se data ini é anterior a data fim
      if (state.dataIni && state.dataFim && new Date(state.dataIni) > new Date(state.dataFim)) {
          setErroDataIni(true);
          setErroDataFim(true);
          erro = true;
      }

      if (erro){
        return;
      } else {
        console.log(state.intervalo)
        dispatch(actionGetRptLogPortico(state))
      }
    }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 co-sm-12">
          <div className="mb-20">
            {erroFormato && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione um formato de arquivo.
                </span>
            )}
            <Select
              size="sm"
              label="Formato do arquivo"
              options={factoryFields.typesReport}
              onChange={handlerChange}
              name="reportType"
            />
            {erroSetor && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione um canal de inspeção.
                </span>
            )}
            <FilterSetorMonitora cb={getSetorSelected} />
            {erroDataIni && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione uma data de início.
                </span>
            )}
            <Input
              type="datetime-local"
              size="sm"
              label="Data Início"
              name="dataIni"
              onChange={handlerChange}
            />
            {erroDataFim && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione uma data de fim.
                </span>
            )}
            <Input
              type="datetime-local"
              size="sm"
              label="Data Fim"
              name="dataFim"
              onChange={handlerChange}
            />
            {erroIntervalo && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione um intervalo válido.
                </span>
            )}
            <Select
              size="sm"
              label="Intervalo"
              options={factoryFields.intervaloRptPortico}
              onChange={handlerChange}
              name="intervalo"
            />
          </div>
          <Button size="sm" onClick={handlerReport} loading={getRptLogPortico.status === "loading"}>
            Baixar Relatório
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RptLogPortico