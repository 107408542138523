import request from "../../../helpers/request";

export interface IRequestGetRptGrafDemandas {
  idSetor: string;
  reportType: string;
  // dataIni: string;
  // dataFim: string;
  dataRelatorio: string;
}

export const getRptGrafDemandas = async ({
  idSetor,
  reportType,
  dataRelatorio
  // dataIni,
  // dataFim
}: IRequestGetRptGrafDemandas) => {
    console.log(idSetor, reportType, dataRelatorio)
  const _reportType = reportType ? `reportType=${reportType}` : ""
  // const _dataIni = dataIni ? `&dataIni=${dataIni}` : ""
  // const _dataFim = dataFim ? `&dataFim=${dataFim}` : ""
  const _idSetor = idSetor ? `&idSetor=${idSetor}` : ""
  const _dataRelatorio = dataRelatorio ? `&dataRelatorio=${dataRelatorio}` : ""

  const str = `${_reportType}${_idSetor}${_dataRelatorio}`
  const url = `${process.env.REACT_APP_API}/Relatorio/RptGrafDemandas?${str}`;
  const result = await request({ url, responseType: "blob" });
  return result;
};
