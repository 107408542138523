import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAlertasByUf } from './services'
import { TResponseAlertasByUF } from './types'

const initialState = {
  status: "",
  data: [] as Array<TResponseAlertasByUF>
}

export const actionGetAlertasByUF = createAsyncThunk<TResponseAlertasByUF, string>(
  "ACTION_GET_ALERTAS_BY_UF",
  async (uf) => {
    const result = await getAlertasByUf(uf)
    return result
  }
)


const sliceGetAlertasByUF = createSlice({
  name: "GET_ALERTAS_BY_UF",
  initialState,
  reducers: {
    actionResetGetAlertasByUF: (state) => {
      state.data = []
    }
  },
  extraReducers: {
    [`${actionGetAlertasByUF.pending}`]: (state) => {
      state.status = "loading"
    },
    [`${actionGetAlertasByUF.fulfilled}`]: (state, { payload }) => {
      state.status = "success"
      state.data = payload
    },
    [`${actionGetAlertasByUF.rejected}`]: (state) => {
      state.status = "error"
    },
  }
})

export default sliceGetAlertasByUF.reducer
export const { actionResetGetAlertasByUF } = sliceGetAlertasByUF.actions;