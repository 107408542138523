import request from "../../../helpers/request"


export const getDashboardAlertas = async () => {
  const date = new Date()
  const currentMonth = date.getMonth() + 1
  const year = date.getFullYear()
  const url = `${process.env.REACT_APP_API}/hub/GetCardDashboardAlertas?mes=${currentMonth - 1}&ano=${year}`
  const result = await request({ url })
  return result.data
}