import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getDashboardAlertas } from './services'
import { TResponseDashboardAlertas } from './types'

const initialState = {
  status: "",
  data: {}
}

export const actionGetDashboardAlertas = createAsyncThunk<TResponseDashboardAlertas>(
  "ACTION_GET_DASHBOARD_ALERTAS",
  async () => {
    const result = await getDashboardAlertas()
    return result
  }
)


const sliceGetDashboardAlertas = createSlice({
  name: "GET_DASHBOARD_ALERTAS",
  initialState,
  reducers: {},
  extraReducers: {
    [`${actionGetDashboardAlertas.pending}`]: (state) => {
      state.status = "loading"
    },
    [`${actionGetDashboardAlertas.fulfilled}`]: (state, { payload }) => {
      state.status = "success"
      state.data = payload
    },
    [`${actionGetDashboardAlertas.rejected}`]: (state) => {
      state.status = "error"
    },
  }
})

export default sliceGetDashboardAlertas.reducer