import React, { useEffect, useState } from "react";
import { actionGetRptLogSistema } from "../../../features/relatorios/rptLogSistema/slices";
import factoryFields from "../../../helpers/factory-fields";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Button from "../../Button";
import Input from "../../Inputs";
import Select from "../../Select";

const initialState = {
  //idSetor: '',//valor padrão
  reportType: '',
  dataIni: '',
  dataFim: ''
} as any;

const RptLogSistema = () => {
  const [state, setState] = useState(initialState);
  const dispatch = useAppDispatch();
  const { getRptLogSistema } = useAppSelector((state) => state);
  const [erroDataIni, setErroDataIni] = useState(false);
  const [erroDataFim, setErroDataFim] = useState(false);
  const [erroFormato, setErroFormato] = useState(false);

  // useEffect(() => {
  //   dispatch(actionGetRptHistoricosDispositivos());
  // }, []);

  const handlerChange = (e: any) => {
    const { name, value } = e.currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  };

  //const handlerReport = () => dispatch(actionGetRptLogSistema(state))
  const handlerReport = () => {
    let erro = false;

    if (!state.reportType) {
      setErroFormato(true);
      erro = true;
    } else {
      setErroFormato(false);
    }

    if (!state.dataIni) {
      setErroDataIni(true);
      erro = true;
    } else {
      setErroDataIni(false);
    }

    if (!state.dataFim) {
      setErroDataFim(true);
      erro = true;
    } else {
      setErroDataFim(false);
    }

    if (state.dataIni && state.dataFim && new Date(state.dataIni) > new Date(state.dataFim)) {
      setErroDataIni(true);
      setErroDataFim(true);
      erro = true;
    }

    if (erro) {
      return;
    } else {
      console.log(state.idSetor)
      dispatch(actionGetRptLogSistema(state))
    }

  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 co-sm-12">
          <div className="mb-20">
            {erroFormato && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione um formato de arquivo.
                </span>
            )}
            <Select
              size="sm"
              label="Formato do arquivo"
              options={factoryFields.typesReport}
              onChange={handlerChange}
              name="reportType"
            />
            {erroDataIni && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione uma data de início.
                </span>
            )}
            <Input
              type="date"
              size="sm"
              label="data Início"
              name="dataIni"
              onChange={handlerChange}
            />
            {erroDataFim && (
                <span style={{ color: "red", fontSize: "0.875rem" }}>
                  *Por favor, selecione uma data de fim.
                </span>
            )}
            <Input
              type="date"
              size="sm"
              label="data Fim"
              name="dataFim"
              onChange={handlerChange}
            />
          </div>
          <div className="mb-30">
            <Button size="sm" onClick={handlerReport} loading={getRptLogSistema.status === "loading"}>
              Baixar Relatório
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RptLogSistema