import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGetGrupoSetor, actionSelectGrupoSetor } from "../../features/grupo-de-setor/slices";
import factoryOptionsFields from "../../helpers/factory-fields";
import { useAppSelector } from "../../hooks/useAppSelector";
import Select from "./../Select";

const FilterSetorMonitora = ({ cb }) => {
  const dispatch = useDispatch();
  
  const handleChange = ({ target: { value } }: any) => {
      cb(Number(value));
  };

  const {
    getGrupoSetor: { data, status, grupoSetorSelected },
  } = useAppSelector((state) => state);

  React.useEffect(() => {
    dispatch(actionGetGrupoSetor());
  }, [dispatch]);

  // if (status === "loading") {
  //   return <strong>Carregando filtros...</strong>;
  // }

  if (!data) return null;

  return (
    <Select
      options={factoryOptionsFields.grupoSetor(data)}
      /*options={["Dom�stico 1", "Dom�stico 2", "Internacional 2", "Internacional 3", "Internacional Conex�o T2/T3", "Conex�o T3"]}*/
      label="Canais de Inspeção Monitorados"
      name="idSetor"
      onChange={handleChange}
      value={grupoSetorSelected}
      /*value={"Dom�stico 1"}*/
      size="sm"
    />
  );
};

export default FilterSetorMonitora