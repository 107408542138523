// import { mouths } from "./mouths";

// export const optionsChart = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//       position: 'bottom' as const,
//     },
//     title: {
//       display: true,
//       text: 'Equipamentos operantes nas ultimas 6 horas'
//     },
//   },
// }

// export const factoryChart = (target: any, colors: Array<string>, type: string = "line", legend: string) => {
//     if (!target) return;

//     const chartData = {
//        type: type,
//        data: target,
//        fill: false,
//        backgroundColor: colors,
//        tension: 0.1,
//     };

//     //return chartData;
//   if (type === "pie" || type === "bar" || type === "line") {
//     return [
//       {
//         type: type,
//         data: target,
//         label: legend,
//         fill: false,
//         backgroundColor: colors,
//         borderColor: colors,
//         tension: 0.1
//       }
//     ]
//   }
//   const result = target.map(({ legenda, dados }: any, i: number) => ({
//     label: legenda || `Label ${i + 1}`,
//     data: dados,
//     fill: false,
//     backgroundColor: colors[i % colors.length],
//     tension: 0.1
//   }))

//   return result;
// }

// export const factoryLabels = (target: any) => {
//   if (!target) return;

//   const result = target.map((label: string) => {
//     const year = label.split("-")[0]
//     const mouth = label.split("-")[1]
//     return `${mouths[Number(mouth) - 1]} ${year}`
//   })

//   return result
// }
import { mouths } from "./mouths";

export const optionsChart = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
    },
  },
}

export const factoryChart = (target: any, colors: Array<string>, type: string = "line") => {
  //console.log("factoryChart output:", factoryChart([60, 40], ["#FF6384", "#36A2EB"], "pie"));
  if (!target) return [];
  if (type === "pie") {
    return [
      {
        data: target,
        //fill: false,
        backgroundColor: colors,
        //tension: 0.1
      }
    ]
  }
  const result = target.map(({ legenda, dados }: any, i: number) => ({
    label: legenda,
    data: dados,
    fill: false,
    backgroundColor: colors,
    tension: 0.1
  }))

  return result;
}

export const factoryLabels = (target: any) => {
  if (!target) return;

  const result = target.map((label: string) => {
    const year = label.split("-")[0]
    const mouth = label.split("-")[1]
    return `${mouths[Number(mouth) - 1]} ${year}`
  })

  return result
}