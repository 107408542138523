import React from "react"
import { Chart } from "react-chartjs-2"
import Button from "../../components/Button"
import Card from "../../components/Card"
import BaseLayout from "../../components/Layout"
import MapaDoBrasil from "../../components/MapaDoBrasil"
import { actionGetDashboardAlertas } from "../../features/dashboard-geral/alertas/slices"
import { actionGetDashboardGeral } from "../../features/dashboard-geral/slices"
import conectSocket from "../../helpers/conect-socket"
import { factoryChart, factoryLabels, optionsChart } from "../../helpers/factory-charts"
import { objectIsEmpty } from "../../helpers/validators"
import { useAppDispatch } from "../../hooks/useAppDispatch"
import { useAppSelector } from "../../hooks/useAppSelector"
import useIsMobile from "../../hooks/useIsMobile"
import "./styles.scss"

enum ELablesPlanosDeAcao {
  "cardReconhecido" = "Alerta Reconhecido",
  "cardEmAndamento" = "Plano de ação em Andamento",
  "cardEmAndamentoAtrasado" = "Plano de ação em Atrasado",
  "cardAltOkAguardInfo" = "Finalizado aguardando informação",
  "cardAltNaoVizualizado" = "Não visualizado no prazo",
  "cardAltReconhecido" = "Reconhecido no prazo",
  "cardPlanoAcaoPrazoOk" = "Plano de ação executado no prazo",
  "cardPrazoExcedido" = "Prazo Excedido"
}

const ListPlanosDeAcao = ({ data }) => {
  const newData = Object.entries(data);
  return (
    <div className="list-planos-acao">
      {newData.map((item: any) => {
        return (
          <div className="list-planos-acao__item">
            <p>{ELablesPlanosDeAcao[item[0]]}</p>
            <p>
              <strong>{item[1]}</strong>
            </p>
          </div>
        );
      })}
    </div>
  );
};

const TabActionCurrentMonth = ({ data }) => <ListPlanosDeAcao data={data} />;

// const TabActionPreviousMonth = () => {
//   const dispatch = useAppDispatch();

//   const {
//     getDashboardAlertas: { data },
//   } = useAppSelector((state) => state);

//   React.useEffect(() => {
//     dispatch(actionGetDashboardAlertas())
//   }, []);

//   if (Object.keys(data).length === 0) {
//     return null
//   }
//   console.log(data, "data here")

//   return <ListPlanosDeAcao data={data} />;
// };
const TabActionPreviousMonth = ({ data }) => <ListPlanosDeAcao data={data} />;

const PageDashboard = () => {
  const dispatch = useAppDispatch();
  const { getDashboardGeral } = useAppSelector(state => state)
  const isMobile = useIsMobile()

  const initialState = {
    cardNaoVisualizado: 0,
    cardReconhecido: 0,
    cardPlanoAcao: 0,
    cardPrazoExcedido: 0,
    cardProgramado: 0,
    cardTotalLojas: 0,
    cardConfiabilidade: 0,
    graficoAlertas: { datasets: [], labels: [], },
    //graficoCards: { datasets: [], labels: [], },
    graficoCards: {
      datasets: factoryChart([63, 37], ["#518ed5", "#d54d59"], "pie"),
      labels: ["Reconhecido", "Atrasado"],
    },
    //graficoDisponibilidade: { datasets: [], labels: [], },
    graficoDisponibilidade: {
      datasets: factoryChart([80, 20], ["#40bc9a", "#939da0"], "pie"),
      labels: ["Online", "Offline"],
    },
    lastAlertas: [],
    lojasAlertas: [],
    ocorrenciaConsolidadas: [],
    // cardDashboardAlertas: [],
    cardDashboardAlertas: {
      cardReconhecido: "0/0",
      cardEmAndamento: "0/0",
      cardEmAndamentoAtrasado: "0/0",
      cardAltOkAguardInfo: "0/0",
      cardAltNaoVizualizado: "0/0",
      cardAltReconhecido: "0/0",
      cardPlanoAcaoPrazoOk: "0/0",
      cardPrazoExcedido: "0/0",
    },
    statusBrasil: {
      ba: "default",
      se: "default",
      pe: "default",
      al: "default",
      am: "default",
      pa: "default",
      mt: "default",
      ro: "default",
      ac: "default",
      ap: "default",
      rj: "default",
      rs: "default",
      sc: "default",
      pr: "default",
      sp: "OK",
      ms: "default",
      go: "default",
      mg: "default",
      es: "default",
      pi: "default",
      ce: "default",
      rr: "default",
      to: "default",
      ma: "default",
      rn: "default",
      pb: "default",
      df: "default"
    }
  };

  const [state, setState] = React.useState(initialState)
  const [showCurrentMonth, setShowCurrentMonth] = React.useState(true)

  const createGraph = (data) => {
    if (objectIsEmpty(data)) return

    const statusBrasil = data.mapaBrasil.reduce((acc, item) => {
      return {
        ...acc,
        [item.sigla.toLowerCase()]: item.status
      }
    }, {})
    setState({
      ...state,
      cardNaoVisualizado: data.cardNaoVisualizado,
      cardReconhecido: data.cardReconhecido,
      cardPlanoAcao: data.cardPlanoAcao,
      cardPrazoExcedido: data.cardPrazoExcedido,
      cardProgramado: data.cardProgramado,
      cardTotalLojas: data.cardTotalLojas,
      cardConfiabilidade: data.cardConfiabilidade,
      ocorrenciaConsolidadas: data.ocorrenciaConsolidadas,
      lastAlertas: data.lastAlertas,
      cardDashboardAlertas: data.cardDashboardAlertas,
      graficoAlertas: {
        datasets: factoryChart(
          data.dsGrafHistoAlertas,
          data.grafHistoAlertasColors
        ),
        labels: factoryLabels(data.grafHistoAlertasLabels),
      },
      graficoCards: {
        datasets: factoryChart(data.dsGrafCards, data.grafCardsColors, "pie"),
        labels: data.grafCardsLabels,
      },
      graficoDisponibilidade: {
        datasets: factoryChart(data.dsGrafDisp, data.grafDispColors, "pie"),
        labels: data.grafDispLabels,
      },
      statusBrasil: { ...state.statusBrasil, ...statusBrasil },
    });
  }

  // React.useEffect(() => {
  //   dispatch(actionGetDashboardGeral())
  // }, [])

  // React.useEffect(() => {
  //   createGraph(getDashboardGeral.data)
  //   conectSocket({ url: "hubGlobalCliente", hub: "GlobalDadosCliente", cb: createGraph })
  // }, [getDashboardGeral.data])

  return (
    <BaseLayout>
      <div className="container">
        <div className="row mb-sm-20">
          <div className="col-sm-12 col-md-6">
            <Card className="mb-20">
              <div className="list-of-numbers">
                <div className="list-of-numbers__item bg-error">
                  <p className="title-and-number paragraph color-white text-uppercase">
                    {/* <strong>{state.cardNaoVisualizado}</strong>Não visualizados */}
                    <strong>7</strong>Não visualizados
                  </p>
                </div>
                <div className="list-of-numbers__item bg-warning">
                  <p className="title-and-number paragraph color-white text-uppercase">
                    {/* <strong>{state.cardProgramado}</strong>Programado */}
                    <strong>3</strong>Programado
                  </p>
                </div>
              </div>
            </Card>

            <Card className="mb-20" shadow={false} variant="secondary">
              <p className="title-and-number text-uppercase text-center">
                {/* Confiabilidade <strong>{state.cardConfiabilidade}</strong> */}
                Confiabilidade <strong>10</strong>
              </p>
            </Card>

            <Card className="mb-20">
              <div className="consolidated">
                <div className="consolidated__header mb-20">
                  <p>Ocorrências Consolidadas</p>
                </div>
                <div className="consolidated__row-items">
                  {/* {state?.ocorrenciaConsolidadas.map(
                    ({ percentual, valor, periodo }: any) => (
                      <div className="consolidated__item">
                        <p className="paragraph color-disabled">{percentual}</p>
                        <p className="paragraph">
                          <strong>
                            {Number(valor).toLocaleString("pt-br")}
                          </strong>
                        </p>
                        <p className="paragraph color-disabled">{periodo}</p>
                      </div>
                    )
                  )} */}
                  <div className="consolidated__item">
                    <p className="paragraph color-disabled">100%</p>
                    <p className="paragraph">
                      <strong>
                        {Number(5.101).toLocaleString("pt-br")}
                      </strong>
                    </p>
                    <p className="paragraph color-disabled">NOV/2024</p>
                  </div>
                  <div className="consolidated__item">
                    <p className="paragraph color-disabled">99%</p>
                    <p className="paragraph">
                      <strong>
                        {Number(5.102).toLocaleString("pt-br")}
                      </strong>
                    </p>
                    <p className="paragraph color-disabled">NOV/2024</p>
                  </div>
                  <div className="consolidated__item">
                    <p className="paragraph color-disabled">98%</p>
                    <p className="paragraph">
                      <strong>
                        {Number(5.103).toLocaleString("pt-br")}
                      </strong>
                    </p>
                    <p className="paragraph color-disabled">NOV/2024</p>
                  </div>
                  <div className="consolidated__item">
                    <p className="paragraph color-disabled">97%</p>
                    <p className="paragraph">
                      <strong>
                        {Number(5.104).toLocaleString("pt-br")}
                      </strong>
                    </p>
                    <p className="paragraph color-disabled">NOV/2024</p>
                  </div>
                  <div className="consolidated__item">
                    <p className="paragraph color-disabled">96%</p>
                    <p className="paragraph">
                      <strong>
                        {Number(5.105).toLocaleString("pt-br")}
                      </strong>
                    </p>
                    <p className="paragraph color-disabled">NOV/2024</p>
                  </div>
                </div>
              </div>
            </Card>

            <div className="row mb-md-20">
              <div className="col-sm-12 col-md-6 mb-sm-20">
                <Card>
                  <p>Cards</p>
                  {/* {state.graficoCards && (
                    <Chart
                      options={optionsChart}
                      type="pie"
                      data={state.graficoCards}
                    />
                  )} */}
                  <Chart
                    options={optionsChart}
                    type="pie"
                    data={state.graficoCards}
                  />
                </Card>
              </div>
              <div className="col-sm-12 col-md-6 mb-sm-20">
                <Card>
                  <p>Disponibilidade</p>
                  {/* {state.graficoCards && (
                    <Chart
                      options={optionsChart}
                      type="pie"
                      data={state.graficoDisponibilidade}
                    />
                  )} */}
                  <Chart
                    options={optionsChart}
                    type="pie"
                    data={state.graficoDisponibilidade}
                  />
                </Card>
              </div>
            </div>

            <Card className="mb-20">
              <p className="mb-20">Últimos alertas</p>
              <div className="list-alerts">
                {/* {state.lastAlertas.map((alert) => (
                  <div className="list-alerts__item">
                    <p>{alert}</p>
                  </div>
                ))} */}
                <div className="list-alerts__item">
                  <p>Módulo 2: encontra-se offline</p>
                  <p>Módulo 3: encontra-se offline</p>
                  <p>Módulo 4: encontra-se offline</p>
                  <p>Módulo 35: encontra-se offline</p>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-sm-12 col-md-6">
            {/* <Card style={{ minHeight: `${isMobile ? "400px" : "542px"}` }} className="mb-20">
              <p className="mb-20">Mapa do Brasil</p>
              <MapaDoBrasil status={state.statusBrasil} />
            </Card> */}
            <Card>
              <p className="mb-20">Alertas / Planos de ação</p>
              <div className="tab">
                <nav className="tab__nav mb-20">
                  <Button
                    size="sm"
                    variant={!showCurrentMonth ? "primary" : "tertiary"}
                    onClick={() => setShowCurrentMonth(false)}
                  >
                    Mês anterior
                  </Button>
                  <Button
                    size="sm"
                    variant={showCurrentMonth ? "primary" : "tertiary"}
                    onClick={() => setShowCurrentMonth(true)}
                  >
                    Mês atual
                  </Button>
                </nav>
                <div className="tab__content">
                  {showCurrentMonth && (
                    <TabActionCurrentMonth data={state.cardDashboardAlertas} />
                  )}
                  {!showCurrentMonth && <TabActionPreviousMonth data={state.cardDashboardAlertas}/>}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default PageDashboard